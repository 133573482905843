<template>
  <div style="display:flex;flex-direction:column;height:100%">
    <div style="display:flex;margin-bottom: 20px">
      <el-form status-icon label-width="90px" style="width:50%;" :rules="rules">
        <el-form-item label="Номер">
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
            <div style="display: flex">
              <div v-if="editOrder.id > 0" style="width:150px">
                <b><a target="_blank" :href="`http://gene-baza.evrogen.net/ru/Order.php?order=${editOrder.number}`">{{ editOrder.number }}</a></b>
              </div>
              <div v-else>Новый заказ - еще нет номера</div>
            </div>
            <div style="display: flex;justify-content: flex-end">
              <my-el-button
                  type="success"
                  size="mini"
                  :icon="letterIsSend('newOrder') ? 'el-icon-check':''"
                  @click="sendNewOrderMail(metaorder)"
              >
                <i class="el-icon-message"></i>
              </my-el-button>
              <my-el-button
                  v-if="['readyToSend', 'send','done'].includes(editOrder.state)"
                  @click="onBtnShowFeedbackWindow()"
                  type="success"
                  size="mini">Отзыв
              </my-el-button>

              <div v-if="editOrder.state === 'newOrder' || editOrder.state === 'awaitApprove'">
                <my-el-button
                    :disabled="!!errorsForSendToErp"
                    :dis-popover="errorsForSendToErp"
                    @click="onBtnSendToErpClick(false)"
                    type="success"
                    size="mini">Отправить в ERP
                </my-el-button>
              </div>
              <div v-if="editOrder.state === 'progressComplete'">
                <my-el-button
                    :disabled="!!errorOtherUserEdit"
                    :dis-popover="errorOtherUserEdit"
                    @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'readyToSend'})"
                    type="success"
                    size="mini">Получен на склад
                </my-el-button>
              </div>
              <my-el-button delete @click="$emit('delete-order', editOrder.id)"></my-el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Состояние">
          <div style="display: flex;align-items: center;">
            <div style="display: flex;flex-wrap: nowrap;flex-shrink: 0">
              <b>{{ $getEnumValue('OrderStateEnum', editOrder.state) }}</b>
              <div v-if="editOrder.erpId" style="margin-left: 5px;display: flex">
                <a target="_blank" :href="erpUrl">
                  <el-popover trigger="hover" placement="top-end" width="100px">
                    Смотреть в ERP
                    <i class="el-icon-view" slot="reference"></i>
                  </el-popover>
                </a>
              </div>
            </div>
            <div v-if="['progressComplete', 'readyToSend', 'send', 'done'].includes(editOrder.state)"
                 class="my-link" style="width: 85px; margin-left: 10px"
                 @click="$myHttp.postWithDownload('/api/orders/GetPrimerPassport',editOrder.id,`passport_${editOrder.number}.pdf`);">
                 Паспорт</div>
            <div style="margin-bottom: 0;display: flex; flex-wrap: nowrap;align-items: center;width: 100%">
              <div style="white-space: nowrap;margin-left: 20px; margin-right: 5px">Требует оператора</div>
              <el-switch
                  active-color="red"
                  v-model="editOrder.needOperatorAction"
                  @change="!$event && (editOrder.needOperatorActionDescription = null)"
              ></el-switch>
              <el-form-item v-if="editOrder.needOperatorAction" prop="needOperatorActionDescription"
                            style="margin-bottom: 0;width: 100%; margin-right: 15px; flex-shrink: 1">
                <el-input v-model="editOrder.needOperatorActionDescription" style="width:100%;margin-left: 10px;flex-grow: 2"></el-input>
              </el-form-item>

            </div>
          </div>
        </el-form-item>
        <el-form-item label="Дедлайн">
          <div style="display: flex; justify-content: space-between;flex-wrap: nowrap">
            <el-date-picker
                v-model="editOrder.deadline"
                type="date"
                placeholder="Выбрать"
                style="margin-right: 25px"
                :picker-options="{firstDayOfWeek: 1}"
            >
            </el-date-picker>
            <div style="display: flex;flex-wrap: nowrap">
              <div style="margin-right: 25px">
                Странный
              <el-switch
                  v-if = "$store.state.shared.loading === 0"
                  :value="editOrder.isStrange"
                  @input="onBtnSetDefferedOrUrgent({isSet: $event, field: 'isStrange' }, false)"
              ></el-switch>
              <i v-else class="el-icon-loading" ></i>
              </div>
              <my-el-button
                  v-if="editOrder.state === 'newOrder'"
                  :disabled="editOrder.isUrgent"
                  @click="onBtnSetDefferedOrUrgent({isSet: true, field: 'isDeffered' }, true)"
                  type="primary"
                  size="mini">Согласовывается
              </my-el-button>
              <deffered-urgent-switch
                  v-else
                  style="margin-right: 25px"
                  :order="editOrder"
                  field="isDeffered"
                  @change="onBtnSetDefferedOrUrgent"
              ></deffered-urgent-switch>
              <deffered-urgent-switch
                  :order="editOrder"
                  field="isUrgent"
                  @change="onBtnSetDefferedOrUrgent"
              ></deffered-urgent-switch>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Комментарий" style="margin-bottom: 0">
          <el-input type="textarea"
                    style="padding-bottom: 0"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    v-model="editOrder.comment"/>
        </el-form-item>
      </el-form>
      <div style="display: flex; flex-direction: column; width: 50%; border-left: 1px solid #DCDFE6;margin-left: 10px;align-items: flex-end">
        <el-tag v-if="editOrder.forSequence" size="mini" style="margin-bottom: 4px">Заказ для секвенирования</el-tag>
        <div style="display: flex;justify-content: space-between" v-if="noDeletedPrimers.length > 0">
          <el-form label-position="left" label-width="110px" style="margin-left: 10px;margin-right: 10px;max-height: 160px; overflow-y: auto">
            <el-form-item v-for="(item, idx) in modSummary" :label="item.label" style="margin-bottom: 4px" :key="`modSum_${idx}`">
              <el-input readonly
                        :value="item.values"></el-input>
            </el-form-item>
          </el-form>
          <el-form label-position="left" label-width="110px" style="margin-left: 10px;margin-right: 10px">
            <el-form-item label="Праймеров" style="margin-bottom: 4px">
              <el-input readonly :value="noDeletedPrimers.length" style="width: 70px"></el-input>
            </el-form-item>
            <el-form-item v-if="allPrimersLength" label="Общей длиной" style="margin-bottom: 4px">
              <el-input readonly :value="`${allPrimersLength} (${buildScaleGroups()})`"></el-input>
            </el-form-item>
            <el-form-item label="Инозин" v-if="noDeletedPrimers.some(p => p.sequence.toLowerCase().indexOf('i') >=0 )" style="margin-bottom: 4px">
              <el-input readonly
                        :value="`${noDeletedPrimers.reduce((a,p)=> a + p.sequence.toLowerCase().replace(/[^i]/g, '').length , 0 )} (${buildScaleGroups('i')})`"></el-input>
            </el-form-item>
            <el-form-item label="Дизоксиуридин" v-if="noDeletedPrimers.some(p => p.sequence.toLowerCase().indexOf('u') >=0 )" style="margin-bottom: 4px">
              <el-input readonly
                        :value="`${noDeletedPrimers.reduce((a,p)=> a + p.sequence.toLowerCase().replace(/[^u]/g, '').length , 0 )} (${buildScaleGroups('u')})`"></el-input>
            </el-form-item>
            <el-form-item label="Тиофосфат" v-if="noDeletedPrimers.some(p => p.sequence.toLowerCase().indexOf('*') >=0 )" style="margin-bottom: 4px">
              <el-input readonly
                        :value="`${noDeletedPrimers.reduce((a,p)=> a + p.sequence.toLowerCase().replace(/[^*]/g, '').length , 0 )} (${buildScaleGroups('*')})`"></el-input>
            </el-form-item>

          </el-form>
          <price-block :edit-order="editOrder"></price-block>
        </div>
      </div>
    </div>
    <el-table
        :span-method="spanPrimerTable"
        :data="noDeletedPrimers"
        style="width: 100%;font-size: 16px"
        height="100%"
        :border="true"
        :add-button-info="addButtonInfo"
        ref="primerTable"
        :default-sort="{prop: 'numberInOrder'}"
        @selection-change="onChangePrimerSelection"
        v-ls-saver:primerTbl
    >
      <el-table-column
          key="selectionRow"
          v-if="!editPrimer"
          type="selection"
          width="40"
      ></el-table-column>

      <el-table-column type="index" prop="numberInOrder" width="80">
        <template v-slot:header>
            <my-el-button check @click="onBtnSelectPrimers" />
        </template>
        <template slot-scope="scope">
          <template v-if="(editPrimer && editPrimer.id === scope.row.id)">
            <modifiers-buttons v-if="editOrder.type === 'mst'"
                               :in-header="false"
                               @select-modifier="onSelectModifier"/>
            <el-form label-width="100px">
              <el-form-item label="Название">
                <div style="display:flex">
                  <div style="display:flex;flex-wrap: nowrap; width: 100%;padding-right: 10px">
                    <el-input v-model="editPrimer.name" style="width:300px"/>
                    <div style="padding:0 10px 0 20px">Шкала</div>
                    <el-select v-model="editPrimer.scale" style="width:100px;margin-right: 20px" @change="onPrimerChange(editPrimer)">
                      <el-option :key="0.02" :label="0.02" :value="0.02"></el-option>
                      <el-option :key="0.04" :label="0.04" :value="0.04"></el-option>
                      <el-option :key="0.2" :label="0.2" :value="0.2"></el-option>
                      <el-option :key="1" :label="1" :value="1"></el-option>
                    </el-select>
                    <el-checkbox label="clean" v-model="editPrimer.optionsCleaning">Очистка</el-checkbox>
                    <el-checkbox label="keen" v-model="editPrimer.optionsKeen">Кинирование</el-checkbox>
                    <el-checkbox label="keen" v-model="editPrimer.optionsResidiment">Переосаждение</el-checkbox>
                  </div>
                  <span style="width:50px">{{ editPrimer.price ? editPrimer.price : '' }}</span>
                </div>
              </el-form-item>
              <el-form-item label="Сиквенс">
                <div style="display:flex">
                  <div style="width: 100%;padding-right: 10px">
                      <primer-editor ref="primerEditor" v-model="editPrimer" @input="onPrimerChange" :need-modifier="editOrder.type === 'mst' ? 'yes' : 'no'"></primer-editor>
                  </div>
                  <span style="width:50px">{{ editPrimer.sequence.replace(/\*/g, '').length }}</span></div>
              </el-form-item>
            </el-form>
          </template>
          <template v-else>
            {{ scope.row.numberInOrder }}<br/>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Название" width="150" :resizable="true">
        <template slot-scope="scope">
          <my-el-button link
                        @click="setEditPrimer(scope.row)"
                        :disabled="!!(editPrimer || /* (scope.row.erpStatus && scope.row.erpStatus != 100 ) ||*/ editOrder.forSequence || !lockManager.canEdit)"
          >{{ scope.row.name ? scope.row.name : '-' }}
          </my-el-button>
        </template>
        <template v-slot:header>
          <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
            <div style="padding: 0">Название</div>
            <my-el-button plus
                          :disabled="!!editPrimer || editOrder.isPack || editOrder.forSequence || !lockManager.canEdit || !['inProgress', 'newOrder', 'awaitApprove'].includes(editOrder.state)"
                          :dis-popover="errorsForAddPrimerPopover"
                          @click="onBtnAppendPrimerClick"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="editOrder.state !== 'newOrder' && editOrder.state !== 'awaitApprove'" width="170" :resizable="true">
        <template slot-scope="scope">
          <div v-if="!erpIsLoading" style="display: flex; flex-direction: column">
            <div>
              <span v-html="erpStatusName(scope.row.erpStatus)"></span>

              <el-popover
                  placement="top-end"
                  trigger="hover">
                <div>Отложен в ERP: {{ scope.row.holdReason }}</div>
                <i type="warning" v-if="scope.row.erpIsDeffered" slot="reference" class="el-icon-stopwatch" style="margin-left: 3px"></i>
              </el-popover>
            </div>
            <span v-html="erpList(scope.row.erpList)"></span>
            <el-popover
                v-if="scope.row.erpParts && scope.row.erpParts.length > 0"
                placement="top-end"
                trigger="hover">
              <div>
                <div v-for="part in scope.row.erpParts" :key="part.name">
                  {{ part.name }} - {{ erpStatusName(part.status) }}
                </div>
              </div>
              <div slot="reference" class="my-link">Есть части</div>
            </el-popover>

          </div>
          <div v-else>
            <i class="el-icon-loading"></i>
          </div>
        </template>
        <template v-slot:header>
          <div>
            Статус в ERP
            <i @click="loadErpPrimerStates"
               class="el-icon-refresh"
               style="padding-left:5px;cursor:pointer"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column :resizable="true">
        <template slot-scope="scope">
          <primer-viewer :primer="scope.row"></primer-viewer>
        </template>
        <template v-slot:header>
          <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
            <div style="padding: 0">Sequence (5'-3')</div>
            <el-select
                v-if="!editPrimer && selectedPrimers.length > 0"
                value-key="key"
                :checkbox="false"
                placeholder="Действие"
                @change="onChangeGroupAction"
                clearable>
              <template>
                <el-option label="Удалить праймеры" :value="{action:'delete'}"/>
                <el-option label="В отдельный заказ" :value="{action:'split'}"/>
                <el-option v-if="editOrder.id > 0" label="Экспорт праймеров" :value="{action:'export'}"/>
                <el-option-group label="Шкала">
                  <el-option
                      v-for="scale in [0.02, 0.04, 0.2, 1]"
                      :key="scale"
                      :label="scale"
                      :value="{action: 'scale', data: scale}"/>
                </el-option-group>
                <el-option-group label="Добавить опции">
                  <el-option label="Добавить очистку" :value="{action:'optionsCleaning', data: true}"/>
                  <el-option label="Добавить кинирование" :value="{action:'optionsKeen', data: true}"/>
                  <el-option label="Добавить переосаждение" :value="{action:'optionsResidiment', data: true}"/>
                </el-option-group>
                <el-option-group label="Удалить опции">
                  <el-option label="Удалить очистку" :value="{action:'optionsCleaning', data: false}"/>
                  <el-option label="Удалить кинирование" :value="{action:'optionsKeen', data: false}"/>
                  <el-option label="Удалить переосаждение" :value="{action:'optionsResidiment', data: false}"/>
                </el-option-group>
                <el-option-group v-if="editOrder.type === 'mst'" label="Добавить модификаторы">
                  <el-option label="Добавить на 5`" :value="{action:'addMod', data:'left'}"/>
                  <el-option label="Добавить на 3`" :value="{action:'addMod', data:'right'}"/>
                </el-option-group>
                <el-option-group v-if="editOrder.type === 'mst'" label="Удалить модификаторы">
                  <el-option label="Удалить на 5`" :value="{action:'deleteMod', data:'left'}"/>
                  <el-option label="Удалить на 3`" :value="{action:'deleteMod', data:'right'}"/>
                </el-option-group>
              </template>
            </el-select>
          </div>
        </template>


      </el-table-column>
      <el-table-column label="Шкала" width="80" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ scope.row.scale }}
        </template>
      </el-table-column>
      <el-table-column
          v-if="editOrder && editOrder.containsPrimersWithClean"
          label="Очистка" width="70" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ scope.row.optionsCleaning ? "Да" : "" }}
        </template>
      </el-table-column>
      <el-table-column
          v-if="editOrder && editOrder.containsPrimersWithKeen"
          label="Кинирование" width="110" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ scope.row.optionsKeen ? "Да" : "" }}
        </template>
      </el-table-column>
      <el-table-column
          v-if="editOrder && editOrder.containsPrimersWithResidiment"
          label="Переосаждение" width="110" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ scope.row.optionsResidiment ? "Да" : "" }}
        </template>
      </el-table-column>

      <el-table-column label="Длина" width="70" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ scope.row.sequence.replace(/\*/g, '').length }}
        </template>
      </el-table-column>
      <template v-for="letter in ['A', 'C', 'G', 'T', 'I', 'U']">
        <el-table-column
            align="center"
            v-if="letterIsExist(letter)"
            :label="letter" width="40"
            :resizable="true"
            :key="'_' + letter"
        >
          <template slot-scope="scope">
            {{ scope.row.sequence.toUpperCase().split('').filter(r => r === letter).length }}
          </template>
        </el-table-column>
      </template>
      <el-table-column
          label="Стоимость (без скидки)"
          width="115" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ $roundFmt(scope.row.price) }}
        </template>
      </el-table-column>
      <el-table-column
          label="Стоимость (со скидкой)"
          width="115" :resizable="true" align="center">
        <template slot-scope="scope">
          {{ $roundFmt(scope.row.discountPrice) }}
        </template>
      </el-table-column>
      <el-table-column :width="110" align="center">
        <template slot-scope="scope">
          <template v-if="editPrimer && editPrimer.id == scope.row.id">
            <my-el-button cancel key="btn1" @click="onBtnCancelEditPrimerClick"/>
            <my-el-button check key="btn2" :disabled="!canSavePrimer(editPrimer)" @click="onBtnApplyEditPrimerClick"/>
          </template>
          <template v-else>
            <my-el-button delete
                          key="btn4"
                          :disabled="!!(editPrimer || (scope.row.erpStatus && scope.row.erpStatus != 100 ) || editOrder.forSequence || !lockManager.canEdit)"
                          @click="onBtnDeleteSelectedPrimers([scope.row])"/>
          </template>
        </template>
        <template v-slot:header>
          <div style="display:flex; align-items: baseline;padding-right: 0; padding-left: 0">
            <my-el-button type="primary"
                          :disabled="!!editPrimer || editOrder.isPack || editOrder.forSequence || !lockManager.canEdit || !['inProgress', 'newOrder', 'awaitApprove'].includes(editOrder.state)"
                          :dis-popover="errorsForAddPrimerPopover"
                          @click="showImportWindow"
            >Импорт
            </my-el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>

<script>
import _ from 'lodash';
import moment from 'moment-business-days';
import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
import modifiersButtons from '@/components/order/ModifiersButtons';
import { createNewSubOrderForSplitPdtOrPrimer, loadPrimerPrice, recalcPrimerOrder } from '@/utils/orders';
import baseOrderTab from './BaseOrderTab.js';
import tableSaver from '@/utils/tableSaver';
import defferedUrgentSwitch from "./DefferedUrgentSwitch";
import textEditor from '@/components/common/TextEditor';
import { alertWithLog } from '@/components/common/dialogs/dialogUtils';
import PriceBlock from '@/components/order/PriceBlock';
import defferedReasonEditor from '@/components/order/DefferedReasonEditor';
import PrimerEditor from "@/components/common/PrimerEditor";
import PrimerViewer from "@/components/order/PrimerViewer";


let modWindow = {
  name: 'selectModWindow',
  template: `
    <component-window
        title="Добавление модификатора"
        ref="compWindow"
        :maximize="false"
        resi
        @close="onBtnCancelClick">
    <el-select :placeholder="position === 'left' ? '5\` мод' : '3\` мод'"
               style="margin: 5px"
               value-key="id"
               v-model="selectedMod">
      <el-option
          v-for="item in $primerHelper.getSortedModsForPosition( position )"
          :key="item.name"
          :label="item.name"
          :value="item">
        <span>{{ item.name }}</span>
      </el-option>
    </el-select>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button :disabled="!selectedMod" @click="onBtnSet" type="success">Выбрать</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['position', 'onClose'],
  data() {
    return {
      date: new Date(),
      sendMail: true,
      selectedMod: null,
      windowSize: { minHeight: 145, minWidth: 240, height: '145', width: '240' }
    }
  },

  methods: {

    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },

    onBtnSet() {
      this.onClose(this.selectedMod);
      this.$emit('close');
    }
  }
};

// eslint-disable-next-line
let feedbackWindow = {
  name: 'feedbackWindow',
  template: `
    <component-window
        title="Отзыв о заказе"
        ref="feedbackWindow"
        :maximize="false"
        resi
        @close="onBtnCancelClick">
    <el-form v-model="fields" >
      <el-form-item label="Длина собрираемой конструкции/иная характеристика"   prop = 'feedbackLength'>
        <el-input v-model = 'fields.length'></el-input>
      </el-form-item>
      <el-form-item label="Замечания по ходу ПЦР"   prop = 'feedbackPcr'>
        <el-input  type="textarea" :autosize="{ minRows: 2}" v-model = 'fields.pcr'></el-input>
      </el-form-item>
      <el-form-item label="Замечания после результатов сиквенса"   prop = 'feedbackSeq'>
        <el-input v-model = 'fields.seq' type="textarea" :autosize="{ minRows: 2}"></el-input>
      </el-form-item>
      <el-form-item label="Замечания внешнего клиента">
        <el-input v-model = 'fields.clientMessage' type="textarea" :autosize="{ minRows: 2}"></el-input>
      </el-form-item>

    </el-form>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSet" type="success">Сохранить</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['order', 'onClose'],
  data() {
    return {
      fields: {
        length: '',
        seq: '',
        pcr: '',
        clientMessage: ''
      },
      windowSize: { minHeight: 450, minWidth: 600, height: '450', width: '600' }
    }
  },
  mounted() {
    this.fields.length = this.order.feedbackLength;
    this.fields.pcr = this.order.feedbackPcr;
    this.fields.seq = this.order.feedbackSeq;
    this.fields.clientMessage = this.order.feedbackClientMessage;
  },

  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },

    onBtnSet() {
      this.onClose(this.fields);
      this.$emit('close');
    }
  },
};

let selectPrimersWindow = {
  name: 'selectPrimersWindow',
  template: `
    <component-window
        title="Выделить праймеры"
        ref="feedbackWindow"
        :maximize="false"
        resi
        @close="onBtnCancelClick">
        <div style="display: flex;flex-direction: column;">
          <div style="margin-bottom: 10px">
            Введите значения через запятую или укажите диапазон (формат: "<b>25-30</b>") или "<b>/3</b>" - каждый третий;
            если указать "<b>-20</b>" или "<b>20-</b>", выделится с первого по двадцатый или с двадцатого по последний соответственно.
            Возможно комбинирование, например: "-20, 25-30, /3, 40, 43".
          </div>
          <div style="display: flex;flex-wrap: nowrap">
            <el-input v-model="src"></el-input>
          </div>
        </div>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSet" type="success">Выделить</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['maxNumber', 'onClose'],
  data() {
    return {
      src:'',
      windowSize: { minHeight: 200, minWidth: 600, height: '200', width: '600' }
    }
  },

  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },


    onBtnSet() {
      const regex = new RegExp('(((?<start>\\d*)-(?<end>\\d*))|(/(?<div>\\d+))|(?<num>\\d+))(,|$)', 'g');
      const matches = [...this.src.replace(' ', '').matchAll(regex)];
      const result = matches.reduce((acc, match) => {
        if (match.groups.start || match.groups.end)
        {
          const start = match.groups.start * 1 || 1;
          const end = match.groups.end * 1 || this.maxNumber
          acc.push(..._.range(start, end + 1))
        } else if (match.groups.num) {
          acc.push(match.groups.num * 1);
        } else if (match.groups.div) {
          acc.push(... _.range(1, this.maxNumber + 1).filter(n => n % (match.groups.div * 1) === 0))
        }
        return acc;
      },[]);
      console.log(result)
      this.onClose(result);
      this.$emit('close');
    }
  },
};


export default {
  name: 'primerTab',
  props: ['metaorder', 'editOrder', 'lockManager', 'timestamp'],
  components: { PrimerViewer, PrimerEditor, PriceBlock, defferedUrgentSwitch, modifiersButtons },
  mixins: [baseOrderTab, tableSaver],

  data() {
    return {
      editPrimer: null,
      erpIsLoading: false,
      rules: {
        needOperatorActionDescription: [this.$validateRuleRequired],
      },
      primerStates: {
        100: 'В очереди',
        110: 'Список набирается',
        120: 'В списке',
        140: 'Синтезируется',
        160: 'Полуфабрикат',
        200: 'Готов',
        300: 'Брак',
        400: 'Запланирован в отправку',
        450: 'В отправке',
        500: 'Отменён'
      },
      selectedPrimers: [],
    }
  },

  watch: {
    'editOrder.rowVersion': function() {
      if (this.editOrder.state !== 'newOrder' && this.editOrder.state !== 'awaitApprove') {
        this.loadErpPrimerStates();
      }
    },

    'editOrder.discountPercent': async function(val) {
      await loadPrimerPrice(this, this.editOrder.elements.filter(p => !p.forDelete), val);
      recalcPrimerOrder(this.editOrder);
    }
  },

  async mounted() {
    if (this.editOrder.state !== 'newOrder' && this.editOrder.state !== 'awaitApprove') {
      await this.loadErpPrimerStates();
    }
  },
  methods: {
    async onBtnSelectPrimers() {
      const maxNumber = Math.max(...this.noDeletedPrimers.map(p => p.numberInOrder));
      let selectedNumbers = await this.$showWindowAsync(selectPrimersWindow, { id: -1, maxNumber });
      if (!selectedNumbers) {
        return;
      }

      this.noDeletedPrimers.filter(e => selectedNumbers.includes(e.numberInOrder))
        .forEach(row => this.$refs.primerTable.toggleRowSelection( row, true));
    },



    async onBtnShowFeedbackWindow() {
      let result = await this.$showWindowAsync(feedbackWindow, { id: -1, order: this.editOrder });
      if (!result) {
        return;
      }
      this.editOrder.feedbackLength = result.length;
      this.editOrder.feedbackPcr = result.pcr;
      this.editOrder.feedbackSeq = result.seq;
      this.editOrder.feedbackClientMessage = result.clientMessage;

      this.$emit('set-primers-feedback', this.editOrder.id);
    },
    async onChangeGroupAction(action) {
      switch ( action.action ) {
        case 'split':
          return this.splitOrder();
        case 'delete':
          return this.onBtnDeleteSelectedPrimers(this.selectedPrimers);
        case 'optionsResidiment':
        case 'optionsCleaning':
        case 'optionsKeen':
          return this.modifyOptionsForGroup(action);
        case 'scale':
          return this.setScaleForGroup(action);
        case 'deleteMod':
          return this.deleteModForGroup(action);
        case 'addMod':
          return this.addModForGroup(action);
        case 'export':
          await this.$myHttp.postWithDownload('/api/orders/exportPrimers', this.selectedPrimers.map(p => p.id), `${this.editOrder.number}.xls`)
          break;
      }
    },

    async splitOrder() {
      let newOrderType = null;
      if (this.selectedPrimers.every(p => !p.primerModifierRls || p.primerModifierRls.filter(rl => !rl.forDelete).length === 0)) {
        newOrderType = 'sst';
      } else if (this.selectedPrimers.every(p => p.primerModifierRls && p.primerModifierRls.filter(rl => !rl.forDelete).length > 0)) {
        newOrderType = 'mst';
      } else {
        await alert('Выбраны праймеры разных типов - не буду создавать заказ.');
        return;
      }

      if (!await confirm("Вынести выбранные элементы в отдельный заказ?")) {
        return;
      }

      let withNewDelivery = await confirm("Сформировать новую доставку для создаваемого заказа?", 'Сформировать', null, true);
      let newOrder = createNewSubOrderForSplitPdtOrPrimer(this.metaorder, this.editOrder, this.deliverys, this.$settings.deadlines, withNewDelivery, newOrderType);

      newOrder.elements = this.editOrder.elements.filter(el => this.selectedPrimers.some(r => r.id === el.id));
      this.editOrder.elements = this.editOrder.elements.filter(el => !newOrder.elements.some(n => n.id === el.id));
      newOrder.elements.forEach((el, id) => {
        el.orderId = 0;
        el.numberInOrder = id + 1
      });
      this.editOrder.elements.forEach((el, id) => {
        el.numberInOrder = id + 1
      });
      recalcPrimerOrder(this.editOrder);
      recalcPrimerOrder(newOrder);
    },

    async addModForGroup(action) {
      let modifier = await this.$showWindowAsync(modWindow, { id: -1, position: action.data });
      if (!modifier) {
        return;
      }
      this.selectedPrimers.forEach(primer => {
        this.$primerHelper.insertModifier(primer, modifier, action.data);
        primer.withChanges = true;
      });
      await this.recalcForGroupAction(this.editOrder.elements);
    },

    async deleteModForGroup(action) {
      if (!await confirm(`Удалить модификатор ${action.data === 'left' ? 5 : 3}\` для выбранных праймеров?`)) {
        return;
      }
      this.selectedPrimers.forEach(pr => {
        this.$primerHelper.deleteModifierByPositionName(pr, action.data);
        pr.withChanges = true;
      });
      await this.recalcForGroupAction(this.editOrder.elements);
    },

    async setScaleForGroup(action) {
      if (!await confirm(`Установить шкалу ${action.data}  для выбранных праймеров?`)) {
        return;
      }
      this.selectedPrimers.forEach(p => {
        if (p.scale !== action.data) {
          p.withChanges = true;
          p.scale = action.data;
        }
      });
      await this.recalcForGroupAction(this.selectedPrimers);
    },

    async modifyOptionsForGroup(action) {
      let names = {
        optionsCleaning: 'очистку',
        optionsKeen: 'кинирование',
        optionResidiment: 'переосаждение'
      }

      if (!await confirm(`${action.data ? 'Установить' : 'Удалить'} ${names[action.action]}  для выбранных праймеров?`)) {
        return;
      }
      this.selectedPrimers.forEach(p => {
        if (p[ action.action ] !== action.data) {
          p.withChanges = true;
          p[ action.action ] = action.data
        }
      });

      await this.recalcForGroupAction(this.selectedPrimers);
    },

    async recalcForGroupAction(primers) {

      primers.forEach(p => p.primerModifierRls.forEach(rl => rl.modifier = this.$store.state.modifiers.items.find(m => m.id === rl.modifierFk)))
      await loadPrimerPrice(this, primers, this.editOrder.discountPercent);
      recalcPrimerOrder(this.editOrder);
    },

    onChangePrimerSelection(primers) {
      this.selectedPrimers = primers;
    },

    async onBtnDeleteSelectedPrimers(primers) {
      if (!await confirm('Удалить праймер(ы)?', 'Удалить')) {
        return;
      }
      primers.forEach(p => {
        p.forDelete = true;
      })
      this.editOrder.elements = this.editOrder.elements
      .filter(p => !(p.id < 0 && p.forDelete))
      this.editOrder.elements
      .filter(p => !p.forDelete)
      .forEach((p, idx) => p.numberInOrder = idx + 1);
      recalcPrimerOrder(this.editOrder);
    },

    async onBtnSetDefferedOrUrgent({ isSet, field }, setApproveState = false) {

      let data = { date: null, reason: null };
      if (isSet && field !== 'isStrange') {
        data = await this.$showWindowAsync(defferedReasonEditor, { field });
        if (!data) {
          return;
        }
      }
      this.$emit('set-deffered-urgent',
                 {
                   orderId: this.editOrder.id,
                   isSet,
                   field,
                   reason: data.reason,
                   date: data.date,
                   setApproveState
                 });
    },

    letterIsExist(letter) {
      return this.noDeletedPrimers.some(p => p.sequence.indexOf(letter) >= 0);
    },

    //----------------------------------------------------------------------------
    async onPrimerChange(primer) {
        let errors = this.$primerHelper.getErrorMessages(primer, this.editOrder.type === 'mst' ? 'yes' : 'no' );
        if (errors.length > 0) {
          primer.price = 0;
          primer.discount_price = 0;
        } else {
          await this.loadPrimerPrice_debounced(() => {
          }, primer, this);
        }
    },

    spanPrimerTable({ row, columnIndex }) {
      if (this.editPrimer && this.editPrimer.id === row.id) {
        let colCount = 7;
        colCount += !!this.editOrder.containsPrimersWithClean
            + !!this.editOrder.containsPrimersWithKeen
            + !!this.editOrder.containsPrimersWithResidiment
            + (this.editOrder.state !== 'newOrder' && this.editOrder.state !== 'awaitApprove' ); //У нового заказа на одну колонку меньше (нет статус в ERP)
        colCount += ['A', 'C', 'G', 'T', 'I', 'U'].reduce((acc, l) => acc + this.letterIsExist(l.toUpperCase()), 0);

        return columnIndex === 0
            ? [1, colCount]
            : ((columnIndex === colCount)
                ? [1, 1]
                : [0, 0]);
      }
      return [1, 1];
    },

    setEditPrimer(row) {
      this.editPrimer = _.cloneDeep(row);
    },

    onBtnCancelEditPrimerClick() {
      if (this.editPrimer.id === 0) {
        this.editOrder.elements = this.editOrder.elements.filter(e => e.id !== 0);
      }
      this.editPrimer = null;
    },

    createNewPrimerIdAndNN: primers => {
      let id = _.min(primers.map(item => item.id));
      id = (!id || id >= 0) ? -1 : (id - 1);
      let nn = _.max(primers.filter(p => !p.forDelete).map(item => item.numberInOrder));
      nn = (!nn || nn === 0) ? 1 : (nn + 1);
      return { id, nn };
    },

    async onBtnApplyEditPrimerClick() {
      const oldPrimer = this.editOrder.elements.find(el => el.id === this.editPrimer.id);
      await loadPrimerPrice(this, this.editPrimer, this.editOrder.discountPercent);
      if (this.editPrimer.id === 0) {
        ({ id: this.editPrimer.id, nn: this.editPrimer.numberInOrder } = this.createNewPrimerIdAndNN(this.editOrder.elements));
      } else {
        //если строка не новая то смотрим были ли изменения что б послать их в erp
        this.editPrimer.withChanges = !this.primersIsEqual(this.editPrimer, oldPrimer);
      }
      this.editOrder.elements.splice(this.editOrder.elements.indexOf(oldPrimer), 1, this.editPrimer);
      recalcPrimerOrder(this.editOrder);
      this.editPrimer = null;
    },

    primersIsEqual(primer1, primer2) {
      return !(primer1.name !== primer2.name
          || primer1.scale !== primer2.scale
          || primer1.optionsCleaning !== primer2.optionsCleaning
          || primer1.optionsKeen !== primer2.optionsKeen
          || !this.$primerHelper.primersIsEqual(primer1, primer2));
    },

    loadPrimerPrice_debounced: _.debounce(async(callback, primer, context) => {
      await loadPrimerPrice(context, primer, context.editOrder.discountPercent);
      callback();
    }, 500),

    buildScaleGroups(letter) {
      if (this.noDeletedPrimers.length === 0) {
        return '';
      }
      const groups = _.groupBy(this.noDeletedPrimers, 'scale');
      return _.orderBy(Object.keys(groups))
      .map(scale => {
        return {
          scale: scale,
          count: groups[ scale ].reduce((acc, pr) =>
                                            acc + (letter ? pr.sequence.toLowerCase().replace(new RegExp(`[^${letter}]`, 'g'), '').length : pr.sequence.length), 0)
        }
      })
      .filter(r => r.count > 0)
      .map(r => r.scale + ' : ' + r.count)
      .join(', ');
    },

    async onBtnDeletePrimerClick(primer, withConfirm = true) {
      if (withConfirm && !await confirm('Удалить праймер?', 'Удалить')) {
        return;
      }
      const idx = this.editOrder.elements.indexOf(primer);
      if (this.editOrder.elements[ idx ].id < 0) {
        this.editOrder.elements.splice(idx, 1);
      } else {
        this.editOrder.elements[ idx ].forDelete = true;
      }
      this.editOrder.elements
      .filter(el => !el.forDelete && el.numberInOrder > primer.numberInOrder)
      .forEach(el => el.numberInOrder--);
      recalcPrimerOrder(this.editOrder);
    },

    onSelectModifier: function(event) {
      if (!this.$refs.primerEditor?.insertModifier) return;
      this.$refs.primerEditor.insertModifier(
          { type: 'modifier',
            text:  event.modifier.name,
            modifierId:  event.modifier.id},
            event.position === 'in' ? null : event.position
      );
    },

    onBtnAppendPrimerClick: function() {
      let primer = {
        id: 0,
        orderId: this.editOrder.id,
        name: '',
        price: 0,
        discountPrice: 0,
        primerModifierRls: [],
        scale: 0.04,
        sequence: '',
        forDelete: false
      };
      this.editOrder.elements.push(primer);
      this.editPrimer = _.cloneDeep(primer);
    },

    canSavePrimer(editPrimer) {
      return this.$primerHelper.getErrorMessages(editPrimer, this.editOrder.type === 'mst' ? 'yes': 'no').length === 0
    },

    //--------------------------------------------------------------------

    erpStatusName(status) {
      return status ? this.primerStates[ status ] : "";
    },


    erpList(list) {
      if (list && list.length > 0) {
        return list.reduce((s, i) => s + "<br/>" + i);
      }
      return "";
    },

    async loadErpPrimerStates() {
      if (!this.editOrder.erpId) {
        await alert(`Для заказа ${this.editOrder.number} нет возможности получить информацию из ERP (отсутствует erpId).`);
        return;
      }

      let dataForSend = [{ id: this.editOrder.erpId, type: this.editOrder.type }];
      this.erpIsLoading = true;
      let response = null;
      try {
        response = await this.$store.dispatch('metaorders/GetErpPrimersState', dataForSend);
      } catch (ex) {
        console.log(ex.message);
        alert("Не удалось связаться с ERP :(");
        return;
      } finally {
        this.erpIsLoading = false;
      }
      this.turnWatch(false);
      response.forEach(respItem => {
        this.editOrder.isPack = respItem.isPack;
        Object.keys(respItem.items).forEach(key => {
          let primer = _.find(this.editOrder.elements, p => p.erpId === key * 1);
          if (primer) {
            primer.erpStatus = respItem.items[ key ].status;
            primer.erpList = respItem.items[ key ].lists;
            primer.erpWithParts = respItem.items[ key ].withPartsInWork;
            primer.erpParts = respItem.items[ key ].parts;
            primer.erpRevision = respItem.items[ key ].revision;
            primer.erpIsDeffered = respItem.items[ key ].isHold;
            primer.holdReason = respItem.items[ key ].holdReason;
          }
        });
      });
      this.turnWatch(true);
    },

    async onBtnSendToErpClick() {
      this.$emit('send-to-erp', this.editOrder.id)
    },

    async showImportWindow() {
      this.$showWindow(textEditor,
                       {
                         title: 'Импорт праймеров',
                         placeholder: ''
                       },
                       async(srcText, setContentFn) => {
                         if (!srcText) {
                           return;
                         }
                         let preparedLines = this.prepareImportLines(srcText);
                         let result = this.importPrimers(preparedLines, this.editOrder.type === 'mst');

                         await loadPrimerPrice(this, this.editOrder.elements.filter(p => !p.price), this.editOrder.discountPercent);
                         recalcPrimerOrder(this.editOrder);

                         if (result.errorLines && result.errorLines.length > 0) {
                           setContentFn(result.errorLines);
                           await alertWithLog('Были ошибки:', result.errors.join('\n'));
                           return false;
                         }
                         return true;
                       });
    },

    prepareImportLines(srcText) {
      return srcText.split('\n')
      .map(line => line.replace(/\t/g, ';').trim());
    },

    importPrimers(lines, isModified) {
      const setTestPrimers = type => {
        const primer = {
          _s: ['тестовый праймер для простого заказа;ACGT;0.04',
            'еще один тестовый праймер для простого заказа;UIACGT;0.04'],
          _m: ['тестовый праймер для модифицированого заказа;ACGT-/Alk/;0.04',
            'тестовый праймер для модифицированого заказа;/6-FAM/-ACGT;0.04',
            'тестовый праймер для модифицированого заказа;AC-/dT-BHQ-2/GT;0.04',
            'тестовый праймер для модифицированого заказа;/6-FAM/-AC-/dT-BHQ-2/GT-/Alk/;0.04'],
          _mx: ['тестовый праймер для mix заказа;ACGT;0.04',
            'еще один тестовый праймер для mix заказа;UIACGT;0.04',
            'тестовый праймер для mix заказа;ACGT-/Alk/;0.04',
            'тестовый праймер для mix заказа;/6-FAM/-ACGT;0.04',
            'тестовый праймер для mix заказа;AC-/dT-BHQ-2/GT;0.04',
            'тестовый праймер для mix заказа;/6-FAM/-AC-/dT-BHQ-2/GT-/Alk/;0.04']
        };

        if (!Object.prototype.hasOwnProperty.call(primer, type)) {
          return null;
        }
        return primer[ type ];
      };

      function pad(num, size) {
        const s = `0000${num}`;
        return s.substr(s.length - size);
      }

      let errorCount = 0;
      let errorLines = '';
      let errors = [];


      const writeLogLine = function(type, message, srcErrorLine) {
        errors.push({
                      line: pad(errorCount + 1, lines.length.toString().length),
                      message
                    });
        errorLines += `${srcErrorLine}\n`;
        errorCount++;
      };
      const findColumns = function(srcCols) {
        const result = { length: 0 };
        let position;
        let nameFieldIsExist;
        const scaleIsFind = srcCols.some((item, index) => {
          const isScale = ['0.02', '0.04', '0.2', '1', '2', '5', '15', '80'].indexOf(item.replace(/,/g, '.')) >= 0;

          if (index > 0 && isScale) {
            position = index >= 2 ? (index - 2) : (index - 1);
            nameFieldIsExist = index >= 2;
            return true;
          }
          return false;
        });

        if (!scaleIsFind) {
          if (srcCols.length === 1) {
            result.length = 1;
            result.sequence = 0;
          } else if (srcCols.length === 2) {
            result.length = 2;
            result.name = 0;
            result.sequence = 1;
          }

          return result;
        }
        ['name', 'sequence', 'scale', 'clean', 'keen'].some((item) => {
          if ((item !== 'name') || (nameFieldIsExist)) {
            result[ item ] = position;
            result.length++;
            position++;
          }
          return position === srcCols.length;
        });
        return result;
      };
      const checkAndReplaceScale = function(cols, scalePosition) {
        [[2, 0.04], [5, 0.04], [15, 0.2], [80, 1]].some((item) => {
          if (item[ 0 ] === parseInt(cols[ scalePosition ])) {
            cols[ scalePosition ] = item[ 1 ].toString();
          }
        });
        return [0.02, 0.04, 0.2, 1].some((item) => {
          const parsedValue = parseFloat(cols[ scalePosition ].replace(/,/g, '.'));
          if (item === parsedValue) {
            cols[ scalePosition ] = parsedValue;
            return true;
          }
          return false;
        });
      };
      const prepareOptions = function(cols, colPos, option) {
        return option in colPos &&
        ['1', 'true', 'да', 'yes', 'y', '+', 'hplc', 'вэжх'].includes(cols[colPos[option]].trim().toLowerCase());
      };



      if (lines.length === 1 && lines[ 0 ].length <= 4) {
        let res = setTestPrimers(lines[ 0 ]);
        if (res) {
          lines = setTestPrimers(lines[ 0 ]);
        }
      }

      const newPrimers = [];

      lines.forEach((line) => {
        if (line.trim().length === 0) {
          return;
        }
        const cols = line.replace(/\t/g, ';').split(';').filter(item => item.trim().length > 0).map(item => item.trim());
        const colPos = findColumns(cols);
        if (!Object.prototype.hasOwnProperty.call(colPos, 'sequence') ||
            (!Object.prototype.hasOwnProperty.call(colPos, 'scale') && colPos.length > 2) ||
            colPos.length > 6) {
          writeLogLine('error', 'Не удалось импортировать, проверьте содержимое строки и порядок полей', line);
          return;
        }
        if (colPos.scale && !checkAndReplaceScale(cols, colPos.scale)) {
          writeLogLine('error', 'Неверно указана шкала синтеза. Допустимые значения - 0.02, 0.04, 0.2 или 1 (nmol)', line);
          return;
        }
        let primer;
        try {
          primer = this.$primerHelper.parse(cols[ colPos.sequence ], true);
        } catch (ex) {
          writeLogLine('error', ex.toString(), line);
          return;
        }

        if ((!isModified && ((primer.primerModifierRls?.length || 0 ) > 0))
            || (isModified && (!primer.primerModifierRls?.length ))
        ) {
          writeLogLine('error', 'Праймер не соответствует типу заказа', line);
          return;
        }

        if ( this.$primerHelper.getPrimerLength(primer) >  this.$primerHelper.maxPrimerLength) {
          writeLogLine('error', `Максимальная длина праймера ${this.$primerHelper.maxPrimerLength}н`, line);
          return;
        }

        primer.name = (!('name' in colPos))
            ? ''
            : (colPos.name > 0 // Если перед названием есть еще калонки -сливаем их в название
                ? cols.slice(0, colPos.name + 1).join(';')
                : cols[ colPos.name ]);
        primer.scale = colPos.scale ? cols[ colPos.scale ] : '0.04';
        primer.optionsKeen = prepareOptions(cols, colPos, 'keen');
        primer.optionsCleaning = prepareOptions(cols, colPos, 'clean');
        if ((!colPos.clean) && this.$primerHelper.isForceClean(primer)) {
          primer.optionsCleaning = true;
        }
        newPrimers.push(primer);
      });
      if (newPrimers.length > 0) {
        newPrimers.forEach(newPrimer => {
          ({ id: newPrimer.id, nn: newPrimer.numberInOrder } = this.createNewPrimerIdAndNN(this.editOrder.elements));
          this.editOrder.elements.push(newPrimer);
        });
      }
      return { errorLines, errors: errors.map(e => `${e.line}  ${e.message}`) };
    },
  },

  computed: {
    modSummary() {
      let positions = {
        Left: '5` ',
        Right: '3` ',
        In: 'in '
      }
      let result = {};

      this.editOrder.elements.filter(prm => !prm.forDelete).forEach(prm => {
         let primerMods = prm.primerModifierRls.map(rl =>  ({
           id: rl.modifier.id,
           name: rl.modifier.name,
           prior: 0,
           position: positions[this.$primerHelper.getModPositionName(prm, rl)]
         }));
         primerMods.forEach(mod => {
           let name = mod.position + ' ' + mod.name;
           if (!result[name]) {
             result[name] = {
               ...mod,
               values: {}
             }
           }
           result[name].values[prm.scale] = result[name].values[prm.scale] ? (result[name].values[prm.scale] + 1) : 1
         })

         if (prm.optionsCleaning && !this.$primerHelper.isForceClean(prm)) {
           if (!result['Очистка']) {
             result['Очистка'] = {
               id: null,
               name: 'Очистка',
               prior: 1,
               position: null,
               values: {}
             }
           }
           result['Очистка'].values[prm.scale] = result['Очистка'].values[prm.scale] ? (result['Очистка'].values[prm.scale] + 1) : 1;
         }
      });
      result = Object.entries(result)
                  .map(e => ({label: e[0], ...e[1], count:  Object.values(e[1].values).reduce((acc, i) => acc + i, 0)  }));
      result.forEach(r => r.values = r.count + ' (' + Object.entries(r.values).toSorted((a,b) => a[0] * 1 - b[0]*1 ).map(e => `${e[0]}: ${e[1]}`).join(', ') +' )'  );
      return result.toSorted((a,b) => {
        if (a.prior !== b.prior) {
          return a.prior - b.prior;
        }
        return a.name.localeCompare(b.name);
      } );
    },


    error() {
      if (this.editPrimer) {
        return 'Нужно закончить редактирование праймера';
      }
      if ((this.editOrder.type === 'mst' &&
              this.editOrder.elements
                .filter(e => !e.forDelete)
                .some(p => (!p.sequence.includes('*')) && (!p.primerModifierRls || p.primerModifierRls.filter(rl => !rl.forDelete).length === 0)))
          || (this.editOrder.type === 'sst' && this.editOrder.elements.filter(e => !e.forDelete).some(p => p.primerModifierRls && p.primerModifierRls.filter(rl => !rl.forDelete).length > 0))
      ) {
        return `Заказ c типом ${this.editOrder.type.toUpperCase()}  содержит праймеры другого типа. Это плохо. Поправить надо.`;
      }
      if ( !this.editOrder.elements.some(e => !e.forDelete) ) {
        return `Заказ должен содержать элементы чтоб сохраненным быть.`;
      }
      return '';
    },

    erpUrl() {
      let erpUrl = this.$settings?.erp?.erpUrl;
      return erpUrl
          ? `${erpUrl}/synthesis/orders#?number=${this.editOrder.number}`
          : null;
    },

    moment() {
      return moment
    },

    allPrimersLength() {
      return this.noDeletedPrimers.reduce((a, p) => a + p.sequence.length, 0);
    },

    addButtonInfo() {
      return (this.editOrder.forSequence)
          ? 'Заказ для сиквенса'
          : (this.editOrder.isPack ? 'Есть расфасованные праймеры' : '')
    },

    noDeletedPrimers() {
      return this.editOrder.elements.filter(e => !e.forDelete);
    },

    errorOtherUserEdit() {
      return (!this.lockManager.canEdit ? 'Заказ редактируется другим пользователем.' : '');

    },

    errorsForSendToErp() {
      return (this.editOrder.isDeffered ? 'Отложенный заказ нельзя никуда отправить.<br/>' : '') +
          (!this.metaorder.user ? 'Не заполнено поле "Заказчик".<br/>' : '') +
          (!this.metaorder.client ? 'Не заполнено поле "Клиент".<br/>' : '') +
          (this.noDeletedPrimers.length === 0 ? 'В заказе нет праймеров.' : '') +
          (this.checkOrderPay ? '' : 'Заказ не оплачен') +
          (!this.lockManager.canEdit ? 'Заказ редактируется другим пользователем.<br/>' : '');
    },

    errorsForAddPrimerPopover() {
      return (this.editPrimer ? 'Перед добавлением нужно завершить редактирование.<br/>' : '') +
          (this.editOrder.isPack ? 'В заказе есть распакованные праймеры.<br/>' : '') +
          (this.editOrder.forSequence ? 'Это заказ для секвенирования.<br/>' : '') +
          (this.editOrder.canEdit ? 'Заказ редактируется другим пользователем.<br/>' : '') +
          (['newOrder', 'inProgress', 'awaitApprove'].includes(this.editOrder.state) ? 'Заказ не находится в состоянии редактирование допускающем.<br/>' : '')
    }
  }
}
</script>

<style>
</style>
